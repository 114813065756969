<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            default="today"
            type="year"
            label="년도"
            name="follwUpExamYear"
            v-model="searchParam.follwUpExamYear"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept 
            :isFirstValue="false"
            type="search" 
            label="부서" 
            name="deptCd" 
            v-model="searchParam.deptCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-field
            label="피검사자"
            name="userId"
            v-model="searchParam.userId" />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="추적검사관리 목록"
      :merge="grid.merge"
      :columns="grid.columns"
      :data="grid.data"
      :editable="editable"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline>
          <c-btn v-if="editable" label="Excel upload" icon="add" @btnClicked="excelUpload" />
          <c-btn v-if="editable" label="추가" icon="add" @btnClicked="add" />
          <c-btn
            v-if="editable" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="grid.data"
            mappingType="PUT"
            label="저장"
            icon="save"
            @beforeAction="save"
            @btnCallback="saveCallback" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="props.row[col.name] === '-'">
          <q-btn
            class="tableinnerBtn"
            flat
            align="center"
            color="blue-6"
            :label="props.row[col.name]"
            @click.stop="linkClick(props.row, col)" />
        </template>
        <template v-else>
          <q-btn
            class="tableinnerBtn"
            unelevated rounded
            align="center"
            :color="setColor(props.row[col.name])"
            :label="props.row[col.name]"
            @click.stop="linkClick(props.row, col)" />
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
    <q-dialog v-model="insertDialog.show" persistent>
      <q-card style="min-width: 450px">
        <q-form ref="editForm">
          <c-card title="피검사자 추가" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-show="editable" 
                  :isSubmit="isInsert"
                  :url="insertUrl"
                  :param="insertDialog.followUpExam"
                  mappingType="POST"
                  label="저장" 
                  icon="save"
                  @beforeAction="insertFollowUpExam"
                  @btnCallback="insertFollowUpExamCallback" />
                <c-btn 
                  label='닫기' 
                  icon="cancel" 
                  @btnClicked="closeDialog"
                />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-field
                  required
                  :editable="editable"
                  :data="insertDialog.followUpExam"
                  deptValue="deptCd"
                  type="dept_user"
                  label="피대상자"
                  name="userId"
                  v-model="insertDialog.followUpExam.userId"
                  @dataChange="userChange">
                </c-field>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-text
                  disabled
                  :editable="editable"
                  label="사번"
                  name="empNo"
                  v-model="insertDialog.followUpExam.empNo">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-text
                  disabled
                  :editable="editable"
                  label="생년월일"
                  name="birthDate"
                  v-model="insertDialog.followUpExam.birthDate">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-select
                  required
                  :editable="editable"
                  :comboItems="opinionItems"
                  type="edit"
                  itemText="follwUpExamStandardOpinionName"
                  itemValue="follwUpExamStandardOpinion"
                  name="follwUpExamStandardOpinion"
                  label="소견"
                  v-model="insertDialog.followUpExam.follwUpExamStandardOpinion"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-select
                  :editable="editable"
                  codeGroupCd="HEA_FOLLW_UP_EXAM_TYPE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="heaFollwUpExamTypeCd"
                  label="구분"
                  v-model="insertDialog.followUpExam.heaFollwUpExamTypeCd"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-text
                  :editable="editable"
                  label="약복용여부"
                  name="takeMedicine"
                  v-model="insertDialog.followUpExam.takeMedicine">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-datepicker
                  required
                  :editable="editable"
                  default="today"
                  type="year"
                  label="추적관리년도"
                  name="follwUpExamYear"
                  v-model="insertDialog.followUpExam.follwUpExamYear"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-text
                  :editable="editable"
                  label="비고"
                  name="remark"
                  v-model="insertDialog.followUpExam.remark">
                </c-text>
              </div>
            </template>
          </c-card>
        </q-form>
      </q-card>
    </q-dialog>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: "follwUpExam",
  data() {
    return {
      searchParam: {
        plantCd: null,
        follwUpExamYear: '',
        deptCd: '',
        userId: '',
        searchedPlantCd: null,
      },
      grid: {
        merge: [
          { index: 0, colName: 'userId', },
          { index: 1, colName: 'userId', },
          { index: 2, colName: 'userId', },
          { index: 3, colName: 'userId', },
          { index: 4, colName: 'userId', },
          { index: 5, colName: 'userId', },
          { index: 6, colName: 'userId', },
        ],
        columns: [
          {
            fix: true,
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            sortable: true,
            style: 'width: 110px',
          },
          {
            fix: true,
            name: 'follwUpExamYear',
            field: 'follwUpExamYear',
            label: '년도',
            align: 'center',
            sortable: true,
            style: 'width: 80px',
          },
          {
            fix: true,
            name: 'upDeptName',
            field: 'upDeptName',
            label: '상위부서',
            align: 'center',
            sortable: true,
            style: 'width: 120px',
          },
          {
            fix: true,
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            sortable: true,
            style: 'width: 120px',
          },
          {
            fix: true,
            name: 'empNo',
            field: 'empNo',
            label: '사번',
            align: 'center',
            sortable: true,
            style: 'width: 90px',
          },
          {
            fix: true,
            name: 'userName',
            field: 'userName',
            label: '성명',
            align: 'center',
            sortable: true,
            style: 'width: 90px',
          },
          {
            fix: true,
            name: 'birthDate',
            field: 'birthDate',
            label: '생년월일',
            align: 'center',
            sortable: true,
            style: 'width: 90px',
          },
          {
            name: 'follwUpExamStandardOpinion',
            field: 'follwUpExamStandardOpinion',
            label: '소견',
            align: 'center',
            sortable: true,
            style: 'width: 100px',
          },
          {
            name: 'heaFollwUpExamTypeCd',
            field: 'heaFollwUpExamTypeCd',
            label: '구분',
            align: 'center',
            sortable: true,
            style: 'width: 110px',
            type: 'select',
            codeGroupCd: 'HEA_FOLLW_UP_EXAM_TYPE_CD',
          },
          {
            name: 'takeMedicine',
            field: 'takeMedicine',
            label: '약복용<br/>여부',
            align: 'left',
            sortable: true,
            style: 'width: 90px',
            type: 'text'
          },
          {
            name: 'remark',
            field: 'remark',
            label: '비고',
            align: 'left',
            sortable: true,
            style: 'width: 150px',
            type: 'text'
          },
          {
            name: 'examResultName1',
            field: 'examResultName1',
            label: '1월',
            align: 'center',
            sortable: true,
            style: 'width: 60px',
            type: 'custom',
            month: '1',
          },
          {
            name: 'examResultName2',
            field: 'examResultName2',
            label: '2월',
            align: 'center',
            sortable: true,
            style: 'width: 60px',
            type: 'custom',
            month: '2',
          },
          {
            name: 'examResultName3',
            field: 'examResultName3',
            label: '3월',
            align: 'center',
            sortable: true,
            style: 'width: 60px',
            type: 'custom',
            month: '3',
          },
          {
            name: 'examResultName4',
            field: 'examResultName4',
            label: '4월',
            align: 'center',
            sortable: true,
            style: 'width: 60px',
            type: 'custom',
            month: '4',
          },
          {
            name: 'examResultName5',
            field: 'examResultName5',
            label: '5월',
            align: 'center',
            sortable: true,
            style: 'width: 60px',
            type: 'custom',
            month: '5',
          },
          {
            name: 'examResultName6',
            field: 'examResultName6',
            label: '6월',
            align: 'center',
            sortable: true,
            style: 'width: 60px',
            type: 'custom',
            month: '6',
          },
          {
            name: 'examResultName7',
            field: 'examResultName7',
            label: '7월',
            align: 'center',
            sortable: true,
            style: 'width: 60px',
            type: 'custom',
            month: '7',
          },
          {
            name: 'examResultName8',
            field: 'examResultName8',
            label: '8월',
            align: 'center',
            sortable: true,
            style: 'width: 60px',
            type: 'custom',
            month: '8',
          },
          {
            name: 'examResultName9',
            field: 'examResultName9',
            label: '9월',
            align: 'center',
            sortable: true,
            style: 'width: 60px',
            type: 'custom',
            month: '9',
          },
          {
            name: 'examResultName10',
            field: 'examResultName10',
            label: '10월',
            align: 'center',
            sortable: true,
            style: 'width: 60px',
            type: 'custom',
            month: '10',
          },
          {
            name: 'examResultName11',
            field: 'examResultName11',
            label: '11월',
            align: 'center',
            sortable: true,
            style: 'width: 60px',
            type: 'custom',
            month: '11',
          },
          {
            name: 'examResultName12',
            field: 'examResultName12',
            label: '12월',
            align: 'center',
            sortable: true,
            style: 'width: 60px',
            type: 'custom',
            month: '12',
          },
        ],
        data: [],
      },
      insertDialog: {
        show: false,
        followUpExam: {
          heaFollwUpExamId: '',  // 추적검사관리 일련번호
          heaFollwUpExamYearId: '',  // 년도별 추적검사관리 일련번호
          follwUpExamYear: '',
          plantCd: '',
          follwUpExamStandardOpinion: null,  // 소견
          empNo: '',  // 피검사자id
          userId: '',  // 피검사자 사번
          userName: '',  // 피검사자명
          deptCd: '',  // 피검사자부서코드
          deptName: '',  // 피검사자부서명
          upDeptCd: '',  // 피검사자상위부서코드
          upDeptName: '',  // 피검사자상위부서명
          birthDate: '',  // 생년월일
          heaFollwUpExamTypeCd: null,  // 구분
          takeMedicine: '',  // 약복용여부
          remark: '',  // 비고
          regUserId: '',  // 등록자
          chgUserId: '',  // 수정자
        }
      },
      opinionItems: [],
      editable: true,
      isInsert: false,
      isSave: false,
      listUrl: '',
      validUrl: '',
      opinionListUrl: '',
      insertUrl: '',
      saveUrl: '',
      excelUploadUrl: '',
      popupOptions: {
        isFull: false,
        width: '70%',
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.hea.fue.list.url
      this.validUrl = selectConfig.hea.fue.valid.url
      this.opinionListUrl = selectConfig.hea.fue.standard.opinion.list.url
      this.insertUrl = transactionConfig.hea.fue.insert.url
      this.saveUrl = transactionConfig.hea.fue.update.url
      this.excelUploadUrl = transactionConfig.hea.fue.excel.url
      // code setting
      this.getOpinions();
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.searchParam.searchedPlantCd = this.$_.clone(this.searchParam.plantCd)
        this.grid.data = _result.data;
      },);
    },
    getOpinions() {
      this.$http.url = this.opinionListUrl;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.opinionItems = _result.data;
      },);
    },
    setColor(examResultName) {
      let color = 'grey';
      switch(examResultName) {
        case '양호':
          color = 'green-7'
          break;
        case '주의':
          color = 'orange'
          break;
        case '위험':
          color = 'red-7'
          break;
        default:
          color = 'grey'
      }
      return color;
    },
    userChange(userId, user) {
      if (userId) {
        this.$set(this.insertDialog.followUpExam, 'plantCd', user.plantCd);
        this.$set(this.insertDialog.followUpExam, 'empNo', user.empNo);
        this.$set(this.insertDialog.followUpExam, 'userName', user.userName);
        this.$set(this.insertDialog.followUpExam, 'deptCd', user.deptCd);
        this.$set(this.insertDialog.followUpExam, 'deptName', user.deptName);
        this.$set(this.insertDialog.followUpExam, 'birthDate', user.birthDate);
      } else {
        this.$set(this.insertDialog.followUpExam, 'plantCd', '');
        this.$set(this.insertDialog.followUpExam, 'empNo', '');
        this.$set(this.insertDialog.followUpExam, 'userName', '');
        this.$set(this.insertDialog.followUpExam, 'deptCd', '');
        this.$set(this.insertDialog.followUpExam, 'deptName', '');
        this.$set(this.insertDialog.followUpExam, 'birthDate', '');
      }
    },
    /* eslint-disable no-unused-vars */ 
    excelUpload() {
      let saveData = this.$_.filter(this.grid.data, item => {
        return item.editFlag === 'C' || item.editFlag === 'U'
      })
      if (saveData && saveData.length > 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', 
          message: '입력 중인 데이터가 존재합니다.\n저장 후 이용바랍니다.',
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      this.popupOptions.title = '추적검사관리 엑셀 업로드';
      this.popupOptions.param = {
        plantCd: this.searchParam.searchedPlantCd,
      };
      this.popupOptions.target = () => import(`${'./followUpExamExcelUpload.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeExcelUploaderPopup;
    },
    closeExcelUploaderPopup(_result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (_result && _result.length > 0) {
        let s_data = this.$_.filter(_result, (item) => { return !item.error_message })
        this.$_.forEach(s_data, item => {
          item.regUserId = this.$store.getters.user.userId;
          item.editFlag = 'C';
        })

        this.$http.url = this.excelUploadUrl;
        this.$http.type = 'POST';
        this.$http.param = s_data
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getList();
        },);
      }
    },
    add() {
      this.insertDialog.show = true
    },
    closeDialog() {
      this.insertDialog.show = false
      Object.assign(this.$data.insertDialog.followUpExam, this.$options.data().insertDialog.followUpExam);
    },
    linkClick(row, col, index) {
      let month = col.month;
      this.popupOptions.title = `${month}월 추적검사관리`;
      this.popupOptions.param = {
        heaFollwUpExamId: row.heaFollwUpExamId,
        heaFollwUpExamYearId: row.heaFollwUpExamYearId,
        heaFollwUpExamMonthId: row[`heaFollwUpExamMonthId${month}`],
        follwUpExamStandardOpinion: row.follwUpExamStandardOpinion,
        month: month,
      };
      this.popupOptions.target = () => import(`${'./follwUpExamMonthDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    save() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {            
            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getOpinions();
      this.getList();
    },
    insertFollowUpExam() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          // 피검사자별로 소견이 동일한것이 있는지 여부 판단
          this.$http.url = this.validUrl;
          this.$http.type = 'GET';
          this.$http.param = this.insertDialog.followUpExam
          this.$http.request((_result) => {
            if (_result.data === 0) {
              window.getApp.$emit('CONFIRM', {
                title: '확인',
                message: '저장하시겠습니까?',
                
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {            
                  this.isInsert = !this.isInsert
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            } else {
              window.getApp.$emit('ALERT', {
                title: '안내', 
                message: '입력하신 피대상자에 대한 소견 정보가 존재합니다.\n다시 기입바랍니다.',
                type: 'warning', // success / info / warning / error
              });
            }
          },);
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    insertFollowUpExamCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.insertDialog.show = false;
      Object.assign(this.$data.insertDialog.followUpExam, this.$options.data().insertDialog.followUpExam);
      this.getList();
    },
  },
};
</script>
